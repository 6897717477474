import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

const DashBoard = () => {

  const BASE_URL = "https://collegematebackend.onrender.com";



  const Navigate = useNavigate();
  const [collegeData, setCollegeData] = useState({
    id: 0,
    name: "",
    location: "",
    logo: "",
    thumbnail: "",
    rating: 0,
    type: "",
    established: 0,
    affiliated: "",
    naac: "",
    intro: "",
    intro2: "",
    courses: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCollegeData((prevData) => ({
      ...prevData,
      [name]: name === 'rating' || name === 'established' ? Number(value) : value,
    }));
  };

  const handleCourseChange = (index, e) => {
    const { name, value } = e.target;
    const updatedCourses = [...collegeData.courses];
    updatedCourses[index] = {
      ...updatedCourses[index],
      [name]: value,
    };
    setCollegeData((prevData) => ({
      ...prevData,
      courses: updatedCourses,
    }));
  };

  const addCourse = () => {
    let id = collegeData.courses.length + 1;
    console.log(id);
    setCollegeData((prevData) => ({
      ...prevData,
      courses: [
        ...prevData.courses,
        {
          id,
          name: "",
          courses: "",
          duration: "",
          fees: "",
          eligibility: "",
          admission: "",
          avgplacement: "",
          highestpackage: "",
        },
      ],
    }));
  };

  
  const token = localStorage.getItem('token');
  useEffect(() => {
    if (!token) {
      alert('You are not authorized to view this page');
      Navigate('/admin');
    }

    fetch(`${BASE_URL}/api/colleges`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        let id = data.colleges.length + 1;
        setCollegeData((prevData) => ({
          ...prevData,
          id: id
        }));
      })
      .catch(err => console.log(err));

  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(collegeData);
    fetch(`${BASE_URL}/api/colleges/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(collegeData)
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      alert('College added successfully');
      Navigate('/admin');
    }
    )
    .catch(err => console.log(err));
  };


  return (
    <div className='flex justify-end'>
      <div className='w-1/4 top-0 left-0 lg:w-1/5 px-4 py-12 gap-12 flex text-white flex-col bg-gray-800 h-screen fixed'>
        <h1 className='text-3xl'>Dashboard</h1>
        <ul className='text-slate-300'>
          <li className='text-xl'>Add College</li>
        </ul>
        <button className='bg-red-500 w-max px-6 py-1 rounded-full text-white mt-4' onClick={() => { localStorage.removeItem('token'); Navigate('/admin') }}>Logout</button>
      </div>
      <div className='w-3/4 lg:w-4/5 px-4 py-12 gap-12 flex flex-col bg-slate-200 '>
        <form className='flex flex-col items-center gap-4'>
          <h2 className='text-4xl font-semibold mb-12'>Add College</h2>
          <input
            type='text'
            name='name'
            placeholder='College Name'
            value={collegeData.name}
            onChange={handleChange}
            className='border p-2 w-[calc(50%-1rem)] rounded-lg'
          />
          <input
            type='text'
            name='location'
            placeholder='Location'
            value={collegeData.location}
            onChange={handleChange}
            className='border p-2 w-[calc(50%-1rem)] rounded-lg'
          />
          <input
            type='text'
            name='logo'
            placeholder='Logo URL'
            value={collegeData.logo}
            onChange={handleChange}
            className='border p-2 w-[calc(50%-1rem)] rounded-lg'
          />
          <input
          type='text'
          name='thumbnail'
          placeholder='Thumbnail URL'
          value={collegeData.thumbnail}
          onChange={handleChange}
          className='border p-2 w-[calc(50%-1rem)] rounded-lg'
          />
          <input
          type='number'
          name='rating'
          placeholder='Rating'
          value={collegeData.rating}
          onChange={handleChange}
          className='border p-2 w-[calc(50%-1rem)] rounded-lg'
          />
          <input
          type='text'
          name='type'
          placeholder='Type'
          value={collegeData.type}
          onChange={handleChange}
          className='border p-2 w-[calc(50%-1rem)] rounded-lg'
          />
          <input
          type='number'
          name='established'
          placeholder='Established'
          value={collegeData.established}
          onChange={handleChange}
          className='border p-2 w-[calc(50%-1rem)] rounded-lg'
          />
          <input
          type='text'
          name='affiliated'
          placeholder='Affiliated'
          value={collegeData.affiliated}
          onChange={handleChange}
          className='border p-2 w-[calc(50%-1rem)] rounded-lg'
          />
          <input
          type='text'
          name='naac'
          placeholder='NAAC'
          value={collegeData.naac}
          onChange={handleChange}
          className='border p-2 w-[calc(50%-1rem)] rounded-lg'
          />
          <input
          type='text'
          name='intro'
          placeholder='Intro'
          value={collegeData.intro}
          onChange={handleChange}
          className='border p-2 w-[calc(50%-1rem)] rounded-lg'
          />
          <input
          type='text'
          name='intro2'
          placeholder='Intro2'
          value={collegeData.intro2}
          onChange={handleChange}
          className='border p-2 w-[calc(50%-1rem)] rounded-lg'
          />
          
          <h2 className='text-2xl font-semibold mt-8'>Courses</h2>
          {collegeData.courses.map((course, index) => (
            <div key={index} className='border flex flex-wrap gap-4 rounded-lg mb-4'>
              <input
                type='text'
                name='name'
                placeholder='Course Name'
                value={course.name}
                onChange={(e) => handleCourseChange(index, e)}
                className='border p-2 w-full md:w-[calc(50%-1rem)] rounded-lg mb-2'
              />
              <input
                type='text'
                name='courses'
                placeholder='Number of Courses'
                value={course.courses}
                onChange={(e) => handleCourseChange(index, e)}
                className='border p-2 w-full md:w-[calc(50%-1rem)] rounded-lg mb-2'
              />
              <input
                type='text'
                name='duration'
                placeholder='Duration'
                value={course.duration}
                onChange={(e) => handleCourseChange(index, e)}
                className='border p-2 w-full md:w-[calc(50%-1rem)] rounded-lg mb-2'
              />
              <input
                type='text'
                name='fees'
                placeholder='Fees'
                value={course.fees}
                onChange={(e) => handleCourseChange(index, e)}
                className='border p-2 w-full md:w-[calc(50%-1rem)] rounded-lg mb-2'
              />
              <input
                type='text'
                name='eligibility'
                placeholder='Eligibility'
                value={course.eligibility}
                onChange={(e) => handleCourseChange(index, e)}
                className='border p-2 w-full md:w-[calc(50%-1rem)] rounded-lg mb-2'
              />
              <input
                type='text'
                name='admission'
                placeholder='Admission'
                value={course.admission}
                onChange={(e) => handleCourseChange(index, e)}
                className='border p-2 w-full md:w-[calc(50%-1rem)] rounded-lg mb-2'
              />
              <input
                type='text'
                name='avgplacement'
                placeholder='Average Placement'
                value={course.avgplacement}
                onChange={(e) => handleCourseChange(index, e)}
                className='border p-2 w-full md:w-[calc(50%-1rem)] rounded-lg mb-2'
              />
              <input
                type='text'
                name='highestpackage'
                placeholder='Highest Package'
                value={course.highestpackage}
                onChange={(e) => handleCourseChange(index, e)}
                className='border p-2 w-full md:w-[calc(50%-1rem)] rounded-lg mb-2'
              />
            </div>
          ))}
          <button
            type='button'
            onClick={addCourse}
            className='bg-blue-500 md:w-[calc(50%-1rem)] text-white p-2 rounded-lg'
          >
            Add Course
          </button>

          <button
            type='submit'
            className='bg-green-500 w-1/4 text-white p-2 rounded-lg mt-4'
            onClick={handleSubmit}
          >
            Submit
          </button>
        </form>
      </div>

    </div>
  )
}

export default DashBoard
