import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

const BASE_URL = "https://collegematebackend.onrender.com";


const AdminPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const Navigate = useNavigate();

    const token = localStorage.getItem('token');

    useEffect(() => {
        if(token){
            console.log("Already Logged in");
            Navigate('/dashboard');
        }
    }
    ,[])

    const handleLogin = () => {
        console.log("Logging in");
        fetch(`${BASE_URL}/api/admin/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({email, password})
        }
        ).then(res => res.json())
        .then(data => {
            console.log(data);
            if(data.success){
                localStorage.setItem('token', data.token);
                Navigate('/dashboard');
            }
            else{
                alert('Invalid Credentials');
            }
        })
        .catch(err => console.log(err))
    }

  return (
    <div className='h-screen flex flex-col  gap-12 justify-center items-center'>
        <h1 className='text-6xl uppercase font-black'>Admin Page</h1>
        <div className='text-2xl'>This is a protected route</div>
        <div className='border rounded-lg   w-1/3 flex flex-col items-center gap-4 p-4'>
            <h1>Admin Login</h1>
            <input type='email' placeholder='Email' onChange={(e) => setEmail(e.target.value)} />
            <input type='password' placeholder='Password' onChange={(e) => setPassword(e.target.value)} />
            <button className='bg-slate-600 w-max px-6 py-1 rounded-full text-white ' onClick={handleLogin}>Login</button>    
        </div>
      
    </div>
  )
}

export default AdminPage
